@import '../../Styles/breakpoints.scss';

.geoml {
    width: unset !important;
    height: 100vh;
    background-color: #fff;

    &__navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px;
        background-color: rgb(216, 221, 216);

        @include respond(phone) {
            margin-bottom: 0px;
        }

        &--title {
            font-size: 20px;
            font-weight: 500;

            @include respond(tab-port) {
              display: none;
            }
        }

        &--logo {
            width: 180px;
            height: 30px;
            img {
                width: 100%;
            }
        }

        &--auth {
            font-weight: 500;
            cursor: pointer;

            button#addAnnotation {
                color: #38A9CF;

                @include respond(tab-port) {
                    display: none;
                  }
            }

            button {
                font-size: 15px;
            }
        }
    }
   
    

    &__content {
    display: flex;
    align-items: center;
       padding-right: 25px;
       margin-top: 25px;

       @include respond(desk) {
        margin-top: 40px;
    }

    @include respond(tab-port-height) {
        margin-top: 10px;
    }

    @include respond(phone) {
        padding: 15px 10px;
        margin-top: 0px;
    }

    @include respond(big-desktop) {
        margin-top: 60px;
    }

    @media only screen and (max-width: 900px) {
        margin-top: 0px;
        flex-direction: column;
    }
       
     
    }

    &__top {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
        margin-right: -30px;

        @media only screen and (max-width: 900px) {
            flex-direction: row;
            justify-content: space-around;
            margin-right: 0px;
        }

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            margin-top: 0;
        }
        &--2 {
            margin-right: 10px;
            .dowmloadXML {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                @media only screen and (max-width: 600px) {
                    margin-bottom: 0px;
                }

                &__text {
                    margin-bottom: 10px;
                    color: rgb(105, 105, 105);
                }
            }
        }
    }

    // .countyBox {
    //     display: flex;
    //     width: 100%;
    //     align-items: center;
    //     border-radius: 12px;
    //     // overflow: hidden;
    //     &__imgBox {
    //         width: 40px;
    //         margin-top: 10px;
    //         margin-left: 10px;
          
    //         img {
    //             width: 100%;
    //         }
    //     }
    // }

    .landingPage {
        height: calc(100vh - 60px - 41px);
        width: 100%;
          background: linear-gradient(
            to right bottom,
            rgba(#F5AA57, 0.6),
            rgba(#24A0CA, 0.6)
          ),
          url(./jonah_oil_fields.jpg) no-repeat center fixed;     
          background-size: cover;
          position: relative;

          @media only screen and (max-width: 600px) {
            font-size: 13px;
            margin-bottom: -20px;
        }
          &__content {
              background-color: rgba(#90A299, 0.7);
              width: 600px;
              text-align: center;
              padding: 20px 20px 50px 20px;
              border-radius: 12px;
              position: absolute;
              top: 40%;
              left: 50%;
              transform: translate(-50%, -50%);

              @media only screen and (max-width: 700px) {
                  width: 500px;
              }

              @media only screen and (max-width: 600px) {
                width: 270px;
              }

              h1 {
                  font-weight: 500 !important;
                  letter-spacing: 0.7px;

                  @media only screen and (max-width: 600px) {
                    font-size: 18px;
                }
              }

              button {
                  text-transform: unset;
                  font-size: 15px;
                  background-color: #24A0CA;
                  color: #000;

                  @media only screen and (max-width: 600px) {
                    font-size: 13px;
                }
              }
          }
    }
}
.container {
    width: 250px;
    height: 200px;
    border: 1px solid #720570;
    margin: 50px;
}

.bar {
   fill: #720570;
}

.frackingData {
    // width: 80%;
    // margin: 0 auto;
    //padding-bottom: 100px;
    height: 100%;

    @media only screen and (max-width: 600px) {
        width: 100%;
        height: 500px;
        margin-top: 20px;
        margin-left: -20px;
    }

    .loadingBox {
        padding-top: 200px;
        height: 500px;
        margin-left: 50%;
    }
}

.d3map {
    position: relative;

    @media only screen and (max-width: 600px) {
        margin-bottom: -30px;
    }
    &__label {
        font-size: 14px;
        position: absolute;
        z-index: 1;
        cursor: pointer;
        
        &--1 {
            top: 60px;
            left: 60px;
            color: #fff;
        }

        &--2 {
            top: 50px;
            left: 130px;
            color: #fff;
        }

        &--3 {
            top: 100px;
            left: 140px;
        }

        &--4 {
            top: 30px;
            left: 235px;
        }
    }
}

.downloadCsv--geoml {
    .MuiPaper-root {
      max-height: unset !important; 
      max-width: unset !important;
      height: 530px;
    }
  }

.leafletMain {
    width: 100%; 
    height: 75vh;
    margin-left: 40px;

    @media only screen and (max-width: 900px) {
        margin-top: 30px;
        margin-bottom: 150px;
    }

    @media only screen and (max-width: 600px) {
        margin-top: 30px;
        margin-bottom: 20px;
        height: 45vh;
    }

    &__loader {
        position: fixed;
        z-index: 11000;
        left: 45%;
        top: 45%;
    }

    &__icons {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .icon {
            display: flex;
            align-items: center;
            cursor: pointer;

            &__2 {
                margin: 0 30px;
            }
        }
        .iconText {
            font-size: 18px;
            letter-spacing: 0.4px;
        }
        .iconBox {
            width: 35px;
            height: 35px;
            margin-right: 4px;
            img {
                width: 100%;
            }
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

.leafletSmall {
    width: 340px;
    height: 280px;
    margin: 0 20px;

    @media only screen and (max-width: 600px) {
        margin-top: 20px;
        width: 90vw;
        margin-right: -20px;

    }
}

.downloadCsv {
    position: fixed;
    bottom: 195px;
    z-index: 1000;
    right: 25px;
    display: flex;
    flex-direction: column;

    &__tables {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .singleTable {
            min-height: 350px;
            margin-bottom: 60px;
            margin-right: 20px;
            &__heading {
                margin-bottom: 10px;
            }
        }
    }
  
    
  @media only screen and (max-width: 800px) {
    bottom: 310px;
  }
  
    &__heading {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    &__dialog {
      padding: 15px 20px;
      width: 750px;
    }
  
    &__option {
      text-decoration: none;
      color: unset;
    }
  
    &__table {
      height: 400px;
      width: 100%;
      margin-top: 10px;
    
      #download {
        margin-top: 20px;  
        padding-bottom: 20px;
        display: flex;
        a{
          text-decoration: none;  border: 1px solid #1565c0;
          padding: 5px 10px;
          border-radius: 4px;
          color: #1565c0;
        }
      }
      .allShipDisclaimer {
        margin-top: 5px;
        font-size: 15px;
        letter-spacing: 0.3px;
        color: rgb(58, 58, 58);
    }
    }
  }

  .frackingCharts {
    //   display: flex;
       width: 100%;
     height: 80vh;
     overflow-y: scroll;
     padding: 0 40px 0px 0;

     &__newUser {
         display: flex;
     }

         
    @media only screen and (max-width: 900px) {
    //    height: 100%;
    margin-bottom: 50px;
    padding-right: 0;
    margin-top: 50px;
    }

      &__box {
        display: flex;
        width: 100%;
        height: 80vh;

        @media only screen and (max-width: 900px) {
           flex-direction: column;
        //    height: unset;
        }

        &--2 {
            margin-top: 20px;

            @media only screen and (max-width: 900px) {
                margin-top: 600px;
            }
        }
      }
      .frackingChart {
          width: 50%;
        //   height: 1800px

        &__newUser {
            height: 80vh;
        }

        @media only screen and (max-width: 900px) {
            width: unset;
        }
      }
  }

  .countyBox {
      display: flex;
      align-items: center;
      &__imgBox {
        width: 35px;
        margin-right: -35px;
          img {
              width: 100%;
          }
      }

      input {
          padding-left: 40px !important;
      }
  }